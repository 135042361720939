<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link v-if="$can('sales_commission.create')" to="/salestargetcommission/sales-commissions/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('sales_commission.add_sales_commission') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="name">{{ $t('sales_commission.commission_name') }}</label>
              <input v-model="filters.name" type="text" id="name" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="employee_id">{{ $t('sales_commission.employee') }}</label>
              <!-- <select name="" id="employee_id" v-model="filters.employee_id" class="custom-select" >
                  <option v-for="row in employees" :value="row.id" :key="row.id">
                      {{ row.full_name }}
                  </option>
              </select> -->
              <multiselect v-model="employee"
                           :placeholder="$t('sales_commission.employee')"
                           label="full_name"
                           track-by="id"
                           :options="employees"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @search-change="getEmployees($event)">
              </multiselect>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('sales_commission.commission_period') }}</label>
              <select name="" id="commission_period" v-model="filters.commission_period" class="custom-select">
                <option v-for="row in commission_period_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>

            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('sales_commission.target_type') }}</label>
              <select name="" id="target_type" v-model="filters.target_type" class="custom-select">
                <option v-for="row in target_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>

            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>


            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary my-auto mb-0 mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger my-auto mb-0 mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="status" slot-scope="props">

            <b-form-checkbox v-if="$can('sales_commission.change_status')"
                             size="lg" @change="changeStatus(props.row.id, props.row.status)"
                             v-model="props.row.status"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>

            <b-form-checkbox v-else
                             size="lg" :disabled="true"
                             v-model="props.row.status"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
          </template>

          <template slot="actions" slot-scope="props">
            <v-btn icon color="pink" v-if="$can('sales_commission.update')" :to="`/salestargetcommission/sales-commissions/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
            </v-btn>

            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('sales_commission.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
            <v-btn icon color="pink" :to="`/salestargetcommission/sales-commissions/view/${props.row.id}`">
              <v-icon small v-b-tooltip.hover :title="$t('view')" class="mr-2">mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-sales-commissions",

  data() {
    return {
      mainRoute: 'salestargetcommission/sales-commissions',
      routeChangeStatus: 'salestargetcommission/sales-commission/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        name: null,
        employee_id: null,
        commission_period: null,
        target_type: null,
        status: null,
      },
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      columns: ['name', 'commission_calculation_name', 'commission_period_name', 'target_type_name', 'status', 'actions'],
      data: [],
      currencies: [],
      commission_period_list: [],
      employees: [],
      employee: null,
      target_type_list: [],
      innerId: null,
      statusId: null,

    }
  },
  watch: {
    employee: function (val) {
      if (val) {
        this.filters.employee_id = val.id;
      } else {
        this.filters.employee_id = null;
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('sales_commission.commission_name'),
          commission_calculation_name: that.$t('sales_commission.commission_calculation'),
          commission_period_name: that.$t('sales_commission.commission_period'),
          target_type_name: that.$t('sales_commission.target_type'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_commissions")}]);
    this.getCommissionPeriod();
    this.getTargetType();
    // this.getEmployees();
  },
  methods: {
    getCommissionPeriod() {
      ApiService.get(this.mainRouteDependency + "/commission_period").then((response) => {
        this.commission_period_list = response.data.data;
      });
    },
    getTargetType() {
      ApiService.get(this.mainRouteDependency + "/target_type").then((response) => {
        this.target_type_list = response.data.data;
      });
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.employee_id = null;
      this.filters.commission_period = null;
      this.filters.target_type = null;
      this.filters.status = null;
      this.employee = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getEmployees(filter) {
      if(filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/employees", {params:{name: filter}}).then((response) => {
          this.employees = response.data.data;
        });
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        status: (status ? '1' : '0'),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },


  },
};
</script>
